import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Toolbar,
} from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";
import moment from "moment";
import axios from "axios";

import html2pdf from "html2pdf.js";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import { companyList } from "../../utils/components/config";
import { generatePDF1 } from "./printCertificate";

const FormPrint = ({selectedFormID,selectedTemplateID,multiple}) => {
  const location = useLocation();
  const [ids, setIds] = React.useState([]);
  const [allIds, setAllIds] = useState([]);
  const [pdfGenerated, setpdfGenerated] = React.useState(false);
  const [downloadingCount, setDownloadingCount] = useState(0);

  const [inputStates, setInputStates] = React.useState({});
  const [inputFields, setInputFields] = React.useState([]);
  const [filteredInputFields, setfilteredInputFields] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { reportID, templateID} = useParams();
  const [newReportID, setNewReportID] = React.useState(reportID || selectedFormID);
  const [newTemplateID, setNewTemplateID] = React.useState(templateID || selectedTemplateID);
  const [printing, setPrinting] = React.useState(false);
  const [formInfo, setFormInfo] = React.useState({});
  const [selectedOption, setSelectedOption] = React.useState({});
  const [Factory, setFactory] = React.useState([]);
  const [Shop, setShop] = React.useState([]);
  const [Product, setProduct] = React.useState([]);
  const currentRoute = window.location.href;
  const routeParts = currentRoute.split('/');
  const lastSegment = routeParts[routeParts.length - 1];
  const [electronicSign, setElectronicSign] = React.useState(false);
  const elementRef = useRef(null);
  const printComponentRef = useRef(null);
  const [downloadPdfloading, setDownloadPdfloading] = React.useState(false);
  let customFontSize = formInfo?.name == "Form13" ? "9px" :"11px";

  const fetchformReport = async (id) => {
    setLoading(true);
    try {
      const res = await axiosWithToken.get(`${BASE_URL}reportValues/${id}`);
      const data = res.data[0];

      if (!data || !data.templateValues) {
        console.log("Template data not found or invalid");
        return;
      }

      const dataObject = data.templateValues && JSON.parse(data.templateValues);

      const newDataObject = { ...dataObject };
      // delete newDataObject.reportDate;
      setInputStates(newDataObject);
    } catch (error) {
      console.error("Failed to fetch template: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTemplate = (id) => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `templates/${id}`)
        .then((res) => {
          let data = res.data[0];
          setFormInfo(data);
          axios.get(data?.url).then((respose) => {
            setInputFields(respose.data);
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch checklist template: ", e);
    }
  };

  const fetchCompetents = (id) => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `competents/${id}`)
        .then((res) => {
          let data = res.data?.[0];
          setSelectedOption(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch checklist Competents: ", e);
    }
  };

  const fetchFactory = () => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `clients`)
        .then((res) => {
          let data = res.data;
          setFactory(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch  Factory : ", e);
    }
  };

  const fetchShop = () => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `shops`)
        .then((res) => {
          let data = res.data;
          setShop(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch  Shop : ", e);
    }
  };

  const fetchProduct = () => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `products`)
        .then((res) => {
          let data = res.data;
          setProduct(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch  Product : ", e);
    }
  };

  var refresh = () => {
    window.location.reload(false);
  };
  const handlePrint = () => {
    setPrinting(true);
    const printContent = document.getElementById("formToPrint");
    const originalContents = document.body.innerHTML;

    if (printContent) {
      document.body.innerHTML = elementRef.current.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
      if(lastSegment !== "print"){
        window.location.reload();
      }

    }
  };

  const generatePDF = async () => {
    setDownloadPdfloading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [5, 5, 5, 5],
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          image: { type: 'png', quality: 0.4 },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
            compress: true,
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("Courier");
      
       // border add for contnet
       const borderWidth = 0.6;
       const leftMargin = 6;
       const borderX = leftMargin;
       const borderY = 5;
       const rightMargin = 595 - leftMargin * 0.95;
       pdf.setLineWidth(borderWidth);
       pdf.rect(borderX, borderY, rightMargin - leftMargin, 830);

      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `form.pdf`;
      a.click();

      setDownloadPdfloading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownloadPdfloading(false);
    }
  };

  const renderStaticSection = () => {
    return (
      <>
        <TableRow>
          <TableCell
            align="center"
            style={{
              backgroundColor: formInfo?.name?.includes("13")
                ? "#4473c5"
                : "#ed7d31",
              color: formInfo?.name?.includes("13")
                ? "#fff"
                : "#000",
                padding: "10px",
                border: "none",
            }}
            colSpan={4}
          >
            <h4
              style={{
                textTransform: "uppercase",
                fontSize: "11.5px",
                marginTop: "5px",
              }}
            >
              <strong>{formInfo?.name}</strong>
            </h4>
            <>
              <h6 style={{ fontSize: "11.5px" }}>
                <b>{formInfo?.title && JSON.parse(formInfo?.title)?.rule}</b>
              </h6>
              <h6 style={{ fontSize: "11.5px", marginBottom: "5px" }}>
                <b><i>{formInfo?.title && JSON.parse(formInfo?.title)?.title}</i></b>
              </h6>
            </>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right" sx={{ border: "none", padding: "5px 0px", }} colSpan={4}>
            {/* {reportDetails && reportDetails.name ? ( */}
            <div>
              <h5 style={{ fontSize: customFontSize }}>
                <b>Report No:{inputStates?.reportNumber || ""} </b>
              </h5>
            </div>
            {/* ) : null} */}
          </TableCell>
        </TableRow>
      </>
    );
  };

  const TextDisplay = ({ text }) => (
    <div style={{ whiteSpace: 'pre-wrap' }}>
      {text}
    </div>
  );

  const renderDynamicTable = () => {
    return (
      <>
        {filteredInputFields?.map((field, index) => {
      const label = field?.label || "";
      const numberMatch = label.match(/^\d+(\.\s|[a-z]\) )/); 
      const number = numberMatch ? numberMatch[0].trim() : "";
      let restLabel = label.replace(number, "").trim();
      if (field?.stateKey === "companyName") {
        return null;
      }
      if(restLabel == "Address of the Factory"){
        restLabel = "Name and address of the Factory"
      }      
          return (
            <TableRow key={index} style={{ height: "10px" }}>
              <TableCell
                style={{
                  backgroundColor: "#d9d9d9",
                  textAlign: "center",
                  padding: "1px 3px",
                  borderRight:"none",
                  fontSize: customFontSize,
                  borderBottom:index === filteredInputFields.length - 1 ? "1px solid black" : "none",
                }}
              >{number}
              </TableCell>
            <TableCell style={{ backgroundColor: "#fff2cd", width: "50%", padding: "1px",borderRight:"none", borderBottom:index === filteredInputFields.length - 1 ? "1px solid black" : "none", }}>
              <div
                style={{
                  marginLeft: "10px",
                  fontSize: customFontSize,
                  textAlign: "left",
                  paddingRight: "5px",
                }}
              >
                  <b>{restLabel || ""}</b>
              </div>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#d9d9d9",
                width: "1%",
                textAlign: "center",
                padding: "1px 3px",
                borderRight:"none",
                fontSize: customFontSize,
                borderBottom:index === filteredInputFields.length - 1 ? "1px solid black" : "none",
              }}
            >
              :
            </TableCell>
            <TableCell style={{ backgroundColor: "#fff2cd", width: "49%", padding: "1px", fontSize: customFontSize, borderBottom:index === filteredInputFields.length - 1 ? "1px solid black" : "none",}}>
              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "5px",
                  fontSize: customFontSize,
                }}
              >
                <b>
                  {field?.stateKey === "addressFactory"
                    ? (
                      <>
                          {Factory?.filter(
                              (ele) => ele.id === inputStates?.["factoryId"]
                          )?.[0]?.name}<br />{inputStates?.["addressFactory"]}
                        </>
                    )
                    : field?.stateKey === "shopId"
                    ? Shop?.filter(
                        (ele) => ele.id === inputStates?.[field?.stateKey]
                      )?.[0]?.name
                    : field?.stateKey === "equipmentId"
                    ? Product?.filter(
                        (ele) => ele.id === inputStates?.[field?.stateKey]
                      )?.[0]?.name
                    : field?.type === "DatePicker"
                    ? new Date(
                        inputStates?.[field?.stateKey]
                      ).toLocaleDateString("en-GB")
                    : inputStates?.[field?.stateKey]?.includes("\n")
                     ? <TextDisplay text={inputStates?.[field?.stateKey]} />
                     : inputStates?.[field?.stateKey]
                    }
                </b>
              </div>
            </TableCell>
          </TableRow>
          )
        } 
        )}
      </>
    );
  };

  const renderSignatureSection = () => {
    function getFormattedDate() {
      const today = inputStates?.reportDate
        ? new Date(inputStates?.reportDate)
        : new Date();
      const formattedDate = today.toLocaleDateString("en-GB");
      return formattedDate;
    }

    function getNextYearDate() {
      // const today = inputStates?.reportDate
      //   ? new Date(inputStates?.reportDate)
      //   : new Date();
      // const nextYear = new Date(today);
      // nextYear.setFullYear(today.getFullYear() + 1);
      // const formattedNextYearDate = inputStates["certificateValidity"] 
      // ? moment(inputStates["certificateValidity"] ).format("DD/MM/YYYY")
      // : nextYear.toLocaleDateString("en-GB");

      let calculateCertificateDueDate = moment(inputStates?.certificateValidity).add(inputStates?.testingFrequency?.split(" ")?.[0],'M').subtract(1, 'days').format('DD/MM/YYYY');    
      return calculateCertificateDueDate;
    }

    const companyId = filteredInputFields.find(field => field?.stateKey === "companyName")?.stateKey;
  const company = companyId ? companyList.find(company => company.id === parseInt(inputStates[companyId], 10)) : null;

    return (
      <div style={{ textAlign: "left",  fontSize: customFontSize }}>
        
        <h6 style={{ fontSize: "11px",margin:"5px 0px",}}>
          {formInfo?.name != "Form13" ? (
            <span>
              I/We certify that on <b>{getFormattedDate()}</b> I/ We thoroughly
              examined the hoist, lift, lifting machine, ropes and lifting tackles
              and details of examinations / test carried out is enclosed herewith
              that the above is a correct report of the result.
            </span>
          ) : (
            <span>
              I/We certify that on <b>{getFormattedDate()}</b> the pressure plant / vessel described above was thoroughly cleaned and (so far as its construction permits) made accessible for thorough examination and for such tests as were necessary for thorough examination and that on the said date. I thoroughly examined this pressure vessel/Plant including its fittings and that the above is true report of my examination.
            </span>
          )}
        </h6>
        <b style={{ fontSize: customFontSize}}> Next Due Date:- {moment(inputStates?.certificateValidity).format('DD/MM/YYYY')}</b>
        <table style={{ pageBreakInside: "avoid", width: "100%" }}>
          <tbody>
            <tr>
            <td style={{ width: "22%" ,  backgroundColor: "#d9d9d9", borderRight:"none"}} rowSpan={6}>
            {company && (
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "5px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: customFontSize,
                    textAlign: "left",
                    borderLeft: "none"
                  }}
                >
                  <div style={{fontSize: customFontSize}}><strong>{company.label}</strong></div>
                  <div style={{fontSize: customFontSize}}>{company.address}</div>
                </div>
              )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  backgroundColor: "#fff2cd",
                  width: "25%",
                  borderBottom: "none",
                  borderRight:"none"
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: customFontSize,
                    textAlign: "right",
                    paddingRight: "5px",
                  }}
                >
                  <b>Signature of Competent Person</b>
                </div>
              </td>
              <td
                style={{
                  backgroundColor: "#d9d9d9",
                  width: "1.3%",
                  textAlign: "center",
                  borderBottom: "none",
                   borderRight:"none"
                }}
              >
                :
              </td>
              <td style={{ width: "25%",borderBottom: "none",  borderRight:"none" }}>
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "5px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: customFontSize,
                  }}
                >
                  {(multiple || electronicSign) && selectedOption?.signPath && (
                  <img src={selectedOption?.signPath} alt="sign" style={{ height: "40px" }} />
                  )}
                </div>
              </td>
              <td style={{ width: "15%" }} rowSpan={5}>
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "5px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: customFontSize,
                    textAlign: "center",
                    borderLeft:"none"
                  }}
                >
                  {(multiple || electronicSign) && 
                  <img src={selectedOption?.stampPath} alt="stamp" style={{height:"100px"}} />
                  }
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  backgroundColor: "#fff2cd",
                  borderBottom: "none",
                  borderRight:"none"
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: customFontSize,
                    textAlign: "right",
                    paddingRight: "5px",
                  }}
                >
                  <b>Name of Competent Person </b>
                </div>
              </td>
              <td
                style={{
                  backgroundColor: "#d9d9d9",
                  width: "15px",
                  textAlign: "center",
                  borderBottom: "none",
                  borderRight:"none"
                }}
              >
                :
              </td>
              <td style={{
                  borderBottom: "none",
                  borderRight:"none"
              }}>
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "5px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: customFontSize,
                  }}
                >
                  {/* <Autocomplete
                options={competents}
                size="small"
                getOptionLabel={(option) => option.personName}
                onChange={(event, newValue) => {
                  setSelectedOption(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.personName}
                    <small>{option.email}</small>
                  </li>
                )}
              /> */}
                  {selectedOption?.personName}
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  backgroundColor: "#fff2cd",
                  borderBottom: "none",
                  borderRight:"none"
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: customFontSize,
                    textAlign: "right",
                    paddingRight: "5px",
                  }}
                >
                  <b>Qualification</b>
                </div>
              </td>
              <td
                style={{
                  backgroundColor: "#d9d9d9",
                  width: "15px",
                  textAlign: "center",
                  borderBottom: "none",
                  borderRight:"none"
                }}
              >
                :
              </td>
              <td style={{
                  borderBottom: "none",
                  borderRight:"none"
              }}>
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "5px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: customFontSize,
                  }}
                >
                  {selectedOption?.qualification}
                </div>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  backgroundColor: "#fff2cd",
                  borderBottom: "none",
                  borderRight:"none"
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: customFontSize,
                    textAlign: "right",
                    paddingRight: "5px",
                  }}
                >
                  <b>Address</b>
                </div>
              </td>
              <td
                style={{
                  backgroundColor: "#d9d9d9",
                  width: "15px",
                  textAlign: "center",
                  borderBottom: "none",
                  borderRight:"none"
                }}
              >
                :
              </td>
              <td style={{
                  borderBottom: "none",
                  borderRight:"none"
              }}>
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "5px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: customFontSize,
                  }}
                >
                  {selectedOption?.address}
                </div>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  backgroundColor: "#fff2cd",
                  borderRight:"none"
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: customFontSize,
                    textAlign: "right",
                    paddingRight: "5px",
                  }}
                >
                  <b>Date</b>
                </div>
              </td>
              <td
                style={{
                  backgroundColor: "#d9d9d9",
                  width: "15px",
                  textAlign: "center",
                  borderRight:"none"
                }}
              >
                :
              </td>
              <td style={{
                  borderRight:"none"
              }}>
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "5px",
                    marginTop: "2px",
                    marginBottom: "2px",
                    fontSize: customFontSize,
                  }}
                >
                  {selectedOption ? getFormattedDate() : ""}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  function filterInputFields(inputFields, stateKeyArray) {
    return inputFields.filter(field => !stateKeyArray.includes(field.stateKey));
}

useEffect(()=>{
  const baseStateKeyArray  = ["competentPerson", "reportNumber","reportDate","factoryId","shopId","equipmentId","totalTest","capacity","renewalInformation ","inspectionDate ","certificateValidity ","inspectionDate","utrasonicTestDate","hydraulicTestDate","internalTestDate","internalExaminationDueDate","certificateValidityDate","renewalInformation","certificateValidity","totaltest","testingFrequency","location"];

  const stateKeyArray = formInfo?.name === "Form13"
    ? [...baseStateKeyArray, "Occupier","location13"]
    : baseStateKeyArray;

  const filteredInputFields = filterInputFields(inputFields, stateKeyArray);
  setfilteredInputFields(filteredInputFields)

},[inputFields])


useEffect(()=>{

if (lastSegment === 'print') {
  setTimeout(() => {
    handlePrint();
  }, 1000);
} 
},[])

  useEffect(() => {
    if (newTemplateID > 0) {
      fetchTemplate(newTemplateID);
    }
    if (newReportID > 0) {
      fetchformReport(newReportID);
    }
  }, [newReportID, newTemplateID]);

  useEffect(() => {
    if (inputStates?.competentPerson)
      fetchCompetents(inputStates?.competentPerson);
  }, [inputStates?.competentPerson]);

  useEffect(() => {
    fetchProduct();
    fetchShop();
    fetchFactory();
  }, []);

  const fetchFormData = () => {
    try {
      let SQLQuery = {
        query: `SELECT id, templateId FROM reportValues WHERE id IN (${ids.join(
          ","
        )})  ORDER BY id DESC`,
        //AND approvedBy IS NOT NULL
      };
      

      axiosWithToken.post(BASE_URL + `dynamic`, SQLQuery).then((res) => {
        let data = res.data;
        const formattedData = data?.map(item => `${item.id},${item.templateId}`);
        const [repoId, tempId] = formattedData[0]?.split(",") || [];
        
        setNewReportID(repoId);
        setNewTemplateID(tempId);
        setAllIds(formattedData);
      });
    } catch (e) {
      console.log("Failed to fetch checklist template: ", e);
    }
  };

//  useEffect(() => {
//     if (ids?.length > 0) fetchFormData();
//   }, [ids]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const idsParam = searchParams.get("ids");
    if (idsParam) {
      const idsArray = idsParam.split(",").map((id) => parseInt(id));
      setIds(idsArray);
    }
  }, [location.search]);


  // useEffect(() => {


  //   if ( pdfGenerated && allIds.length > 1 && downloadingCount < allIds.length) {
  //    const timer = setTimeout(() => {
  //       generatePDF().then(() => {
          
  //        if (downloadingCount+1 < allIds.length) {
  //         const [repoId, tempId] = allIds[downloadingCount+1]?.split(",") || [];
  //         setNewReportID(repoId);
  //         setNewTemplateID(tempId);
         
  //         }
  //         setDownloadingCount((prev) => prev + 1);
  //         // pdfGenerated = false;
  //       });
  //     }, 1000);
  //     // Cleanup function to clear the timeout if the component unmounts
  //     return () => clearTimeout(timer);
  //   }
  // }, [allIds,inputStates,  pdfGenerated]);

  return (
    <>
    {!selectedFormID && (
    <Grid container spacing={2} align="left" sx={{p:2}}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  size="small"
                  id="certificate-certificateviewelectronicsign"
                  options={[
                    { id: 1, label: "With Signed And Stamp" },
                    { id: 2, label: "Without Signed And Stamp" },
                  ]}
                  renderInput={(params) => (
                    <TextField {...params} label="Signed And Stamp" />
                  )}
                  onInputChange={(event, newInputValue) => {
                    if (
                      newInputValue === "With Signed And Stamp" ||
                      newInputValue === ""
                    ) {
                      setElectronicSign(true);
                    } else if (
                      newInputValue === "Without Signed And Stamp"
                    ) {
                      setElectronicSign(false);
                      }
                  }}
                />
              </Grid>
     </Grid>
    )}
    <div
      id="formToPrint"
      style={{ width: "100%", fontSize: "16px", margin: "auto" }}
      >
      <div
      ref={printComponentRef}
        style={{
          width: "99%",
          fontSize: "16px",
          // border: "1px solid black",
          margin: "auto",
          padding: "7px",
        }}
      >
        <Table>
          <TableBody>
            {renderStaticSection()}

            {renderDynamicTable()}
          </TableBody>
        </Table>
        {renderSignatureSection()}
        <div style={{ width: "100%" }} id="header-section"></div>
      </div>
      {reportID  &&
            <Toolbar
            style={{
              padding: "0px",
              overflow: "auto",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{ marginRight: "20px" }}
              onClick={() => {
                generatePDF();
                // if(allIds.length > 1) {                      
                //   setpdfGenerated(true);
                // }else{
                // generatePDF();
                // }
              }}
              // onClick={() =>
              //   generatePDF1({
              //     filteredInputFields,
              //     inputStates,
              //     Factory,
              //     Shop,
              //     Product,
              //     formInfo,
              //   })
              // }
            >
              Report Print
            </Button>
          </Toolbar>
      }

{downloadPdfloading && (
        <DownloadPdfLoaderModal loading={downloadPdfloading} name={"Pdf"} />
      )}

    </div>
    </>
  );
};

export default FormPrint;
